import React, { useState } from 'react';
import './Notifications.css'; // Crée un fichier CSS pour le style
import Header from './Header'; // Intégration du Header
import { db } from './firebase'; // Firebase configuré
import { collection, getDocs } from 'firebase/firestore';

const Notifications = () => {
  const [notificationMessage, setNotificationMessage] = useState('');
  const [title, setTitle] = useState('');
  const [sendDate, setSendDate] = useState('');
  const [isScheduled, setIsScheduled] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSendNotification = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    try {
      // Préparer les données de la notification
      const notificationData = {
        title,
        body: notificationMessage,
      };

      // Récupérer tous les tokens d'inscription depuis Firestore
      const tokensSnapshot = await getDocs(collection(db, 'deviceTokens'));
      const tokens = tokensSnapshot.docs.map((doc) => doc.data().token);

      if (tokens.length === 0) {
        alert('Aucun appareil inscrit pour recevoir des notifications.');
        setLoading(false);
        return;
      }

      // Envoi des notifications via FCM
      const response = await fetch('https://fcm.googleapis.com/fcm/send', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `key=YOUR_SERVER_KEY`, // Remplacez par votre clé de serveur FCM
        },
        body: JSON.stringify({
          notification: notificationData,
          registration_ids: tokens, // Envoyer aux tokens récupérés
        }),
      });

      if (!response.ok) {
        throw new Error('Erreur lors de l\'envoi de la notification');
      }

      console.log('Notification envoyée avec succès :', notificationData);

      // Réinitialiser le formulaire après envoi
      setTitle('');
      setNotificationMessage('');
      setSendDate('');
      setIsScheduled(false);

      alert('Notification envoyée avec succès.');
    } catch (error) {
      console.error('Erreur lors de l\'envoi de la notification :', error);
      alert('Une erreur est survenue lors de l\'envoi de la notification.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Header />

      <div className="notifications-container">
        <h2>Gestion des Notifications</h2>
        <form onSubmit={handleSendNotification}>
          {/* Titre de la notification */}
          <input
            type="text"
            className="notifications-input"
            placeholder="Titre de la notification"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />

          {/* Message de la notification */}
          <textarea
            className="notifications-textarea"
            placeholder="Message de notification"
            value={notificationMessage}
            onChange={(e) => setNotificationMessage(e.target.value)}
            required
          ></textarea>

          {/* Toggle pour planifier la notification */}
          <label className="notifications-switch">
            <input
              type="checkbox"
              checked={isScheduled}
              onChange={() => setIsScheduled(!isScheduled)}
            />
            <span className="notifications-slider-round"></span>
          </label>
          <span className="notifications-switch-label">Planifier l'envoi</span>

          {/* Date de programmation */}
          {isScheduled && (
            <input
              type="datetime-local"
              className="notifications-datetime"
              value={sendDate}
              onChange={(e) => setSendDate(e.target.value)}
              required
            />
          )}

          <button type="submit" className="notifications-submit-button" disabled={loading}>
            {loading ? 'Envoi en cours...' : 'Envoyer la notification'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Notifications;