import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Header.css'; // Assure-toi de créer un fichier CSS pour le style

const Header: React.FC = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Logique de déconnexion
    console.log("Déconnecté");
    // Redirige vers la page de login après la déconnexion
    navigate('/login');
  };

  return (
    <header className="header">
      <nav className="nav">
        <button onClick={() => navigate('/previsions')} className="nav-button">
          Prévisions
        </button>
        <button onClick={() => navigate('/bandeaux')} className="nav-button"> {/* Correction ici */}
          Bandeaux
        </button>
        <button onClick={() => navigate('/notifications')} className="nav-button">
          Notifications
        </button>
        <button onClick={handleLogout} className="logout-button">
          Déconnexion
        </button>
      </nav>
    </header>
  );
};

export default Header;