import React, { useState, useEffect } from 'react';
import './Bandeaux.css';
import Header from './Header';
import { db, storage } from './firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const Bandeau = () => {
  const [isActive, setIsActive] = useState(false);
  const [title, setTitle] = useState('');
  const [bodyText, setBodyText] = useState('');
  
  // Fonction pour obtenir la date et l'heure actuelles au format 'YYYY-MM-DDTHH:MM'
  const getCurrentDateTimeLocal = () => {
    const now = new Date();
    const tzOffset = now.getTimezoneOffset() * 60000; // décalage horaire en millisecondes
    const localISOTime = new Date(now.getTime() - tzOffset).toISOString().slice(0, 16);
    return localISOTime;
  };

  const [startDate, setStartDate] = useState(getCurrentDateTimeLocal()); // Initialiser avec l'heure actuelle
  const [endDate, setEndDate] = useState('');
  const [bannerType, setBannerType] = useState('information');
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [alertInProgress, setAlertInProgress] = useState(false);
  const [uploadProgress, setUploadProgress] = useState<number | null>(null);
  const [uploadError, setUploadError] = useState<string | null>(null);
  const [currentUser, setCurrentUser] = useState<any>(null);

  useEffect(() => {
    // Surveiller l'état d'authentification de l'utilisateur
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user);
        console.log('Utilisateur authentifié:', user.email);
      } else {
        setCurrentUser(null);
        console.log('Aucun utilisateur authentifié.');
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    // Récupérer le bandeau actuel depuis Firestore
    const fetchCurrentBanner = async () => {
      try {
        console.log('Fetching current banner from Firestore...');
        const bannerRef = doc(db, 'banners', 'currentBanner');
        const bannerSnap = await getDoc(bannerRef);

        if (bannerSnap.exists()) {
          const bannerData = bannerSnap.data();
          const now = new Date();

          if (bannerData.endDate.toDate() > now) {
            // Si une alerte est en cours, pré-remplir les champs
            console.log('Current banner is active. Pre-filling form fields.');
            setIsActive(bannerData.isActive);
            setTitle(bannerData.title);
            setBodyText(bannerData.bodyText);
            setStartDate(bannerData.startDate.toDate().toISOString().slice(0, 16));
            setEndDate(bannerData.endDate.toDate().toISOString().slice(0, 16));
            setBannerType(bannerData.bannerType);
            setAlertInProgress(true); // Alerte en cours
          }
        } else {
          console.log('No existing banner found.');
          // Si aucun bandeau n'existe, garder la date de début par défaut (heure actuelle)
        }
      } catch (error: unknown) {
        const errorMessage = error instanceof Error ? error.message : 'Erreur inconnue';
        console.error('Erreur lors de la récupération du bandeau actuel :', errorMessage);
        alert('Une erreur est survenue lors de la récupération du bandeau actuel.');
      }
    };

    fetchCurrentBanner();
  }, []);

  useEffect(() => {
    const checkEndDate = () => {
      const now = new Date();
      if (endDate && new Date(endDate) <= now) {
        // Si la date de fin est atteinte, on met isActive à false
        console.log('End date reached. Deactivating banner.');
        setIsActive(false);
        setAlertInProgress(false);
      }
    };

    // Vérifier toutes les minutes si la date de fin est atteinte
    const intervalId = setInterval(checkEndDate, 60000);

    // Nettoyage de l'intervalle quand le composant est démonté
    return () => clearInterval(intervalId);
  }, [endDate]);

  const handleImageUpload = async (file: File): Promise<string> => {
    try {
      if (!file) {
        console.log('No image file provided.');
        return '';
      }

      console.log('Validating image file type and size...');
      const validImageTypes = ['image/jpeg', 'image/png', 'image/gif'];
      if (!validImageTypes.includes(file.type)) {
        throw new Error('Type de fichier non supporté. Veuillez sélectionner une image JPEG, PNG ou GIF.');
      }

      const maxSizeInBytes = 5 * 1024 * 1024; // 5MB
      if (file.size > maxSizeInBytes) {
        throw new Error('Le fichier est trop volumineux. Veuillez sélectionner une image de moins de 5MB.');
      }

      const imageRef = ref(storage, `banners/${uuidv4()}`);
      console.log(`Uploading image to ${imageRef.fullPath}...`);

      // Création de la tâche d'upload
      const uploadTask = uploadBytesResumable(imageRef, file);

      return await new Promise((resolve, reject) => {
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log(`Upload progress: ${progress}%`);
            setUploadProgress(Math.round(progress));
          },
          (error: unknown) => {
            const errorMessage = error instanceof Error ? error.message : 'Erreur inconnue lors de l\'upload de l\'image.';
            console.error('Erreur lors de l\'upload de l\'image :', errorMessage);
            setUploadError(errorMessage);
            reject(new Error(errorMessage));
          },
          async () => {
            try {
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
              console.log('Image uploadée avec succès. URL de téléchargement :', downloadURL);
              resolve(downloadURL);
            } catch (error: unknown) {
              const errorMessage = error instanceof Error ? error.message : 'Erreur inconnue lors de la récupération de l\'URL de téléchargement.';
              console.error('Erreur lors de la récupération de l\'URL de téléchargement :', errorMessage);
              setUploadError(errorMessage);
              reject(new Error(errorMessage));
            }
          }
        );
      });
    } catch (error: unknown) {
      const errorMessage = error instanceof Error ? error.message : 'Erreur inconnue dans handleImageUpload.';
      console.error('Erreur dans handleImageUpload:', errorMessage);
      setUploadError(errorMessage);
      throw new Error(errorMessage); // Relancer une erreur typée
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setUploadError(null);
    setUploadProgress(null);

    console.log('Submitting banner form...');
    try {
      if (!currentUser) {
        throw new Error('Utilisateur non authentifié. Veuillez vous connecter.');
      }

      let imageUrl = '';

      if (imageFile) {
        console.log('Image file selected. Starting upload...');
        imageUrl = await handleImageUpload(imageFile);
      }

      const updatedEndDate = !isActive && alertInProgress ? new Date() : new Date(endDate);

      const bannerData = {
        isActive,
        title,
        bodyText,
        startDate: new Date(startDate),
        endDate: updatedEndDate,
        bannerType,
        imageUrl,
      };

      console.log('Setting banner data in Firestore:', bannerData);
      await setDoc(doc(db, 'banners', 'currentBanner'), bannerData);

      console.log('Bandeau d\'alerte mis à jour avec succès.');

      if (!isActive && alertInProgress) {
        setAlertInProgress(false);
      }

      if (new Date(endDate) > new Date()) {
        alert('Le bandeau a été mis à jour et reste actif.');
      } else {
        resetForm();
        alert('Le bandeau a été publié avec succès.');
      }
    } catch (error: unknown) {
      const errorMessage = error instanceof Error ? error.message : 'Erreur inconnue lors de la publication du bandeau.';
      console.error('Erreur lors de la publication du bandeau :', errorMessage);
      alert(`Une erreur est survenue : ${errorMessage}`);
    } finally {
      setUploadProgress(null);
    }
  };

  const resetForm = () => {
    setIsActive(false);
    setTitle('');
    setBodyText('');
    setStartDate(getCurrentDateTimeLocal()); // Réinitialiser la date de début à l'heure actuelle
    setEndDate('');
    setBannerType('information');
    setImageFile(null);
  };

  return (
    <div>
      <Header />

      <div className="bandeau-container">
        <h2>Gestion du Bandeau</h2>

        {/* Toggle pour activer/désactiver le bandeau */}
        <label className="bandeau-switch">
          <input
            type="checkbox"
            checked={isActive}
            onChange={() => setIsActive(!isActive)}
          />
          <span className="bandeau-slider-round"></span>
        </label>
        <span className="bandeau-switch-label">Activer le bandeau</span>

        {/* Si le bandeau est actif, afficher les champs */}
        <form onSubmit={handleSubmit}>
          {isActive && (
            <>
              {/* Titre */}
              <input
                type="text"
                className="bandeau-alert-input"
                placeholder="Titre du bandeau"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
              />

              {/* Corps du texte */}
              <textarea
                className="bandeau-alert-textarea"
                placeholder="Corps du texte"
                value={bodyText}
                onChange={(e) => setBodyText(e.target.value)}
                required
              ></textarea>

              {/* Dates de début et de fin */}
              <label>
                Date de début :
                <input
                  type="datetime-local"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  required
                />
              </label>

              <label>
                Date de fin :
                <input
                  type="datetime-local"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  required
                />
              </label>

              {/* Type de bandeau */}
              <label>
                Type de bandeau :
                <select
                  value={bannerType}
                  onChange={(e) => setBannerType(e.target.value)}
                  required
                >
                  <option value="information">Information</option>
                  <option value="alerte-orange">Alerte Orange</option>
                  <option value="alerte-rouge">Alerte Rouge</option>
                </select>
              </label>

              {/* Upload d'image */}
              <label>
                Image associée :
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => {
                    if (e.target.files && e.target.files[0]) {
                      console.log('Image file selected:', e.target.files[0]);
                      setImageFile(e.target.files[0]);
                    }
                  }}
                />
              </label>

              {/* Indicateur de progression de l'upload */}
              {uploadProgress !== null && (
                <div className="upload-progress">
                  <p>Upload en cours : {uploadProgress}%</p>
                  <progress value={uploadProgress} max="100"></progress>
                </div>
              )}

              {/* Affichage des erreurs d'upload */}
              {uploadError && (
                <div className="upload-error">
                  <p>{uploadError}</p>
                </div>
              )}
            </>
          )}

          {/* Bouton pour publier le bandeau */}
          <button
            type="submit"
            className="bandeau-submit-button"
            disabled={uploadProgress !== null && uploadProgress < 100}
          >
            {uploadProgress !== null && uploadProgress < 100 ? 'Téléchargement...' : 'Publier le bandeau'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Bandeau;