import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { getWeatherIcon } from './utils/getWeatherIcon';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from './firebase';
import Header from './Header';

import './Previsions.css';

// Icônes jour
const ClearIcon = `${process.env.PUBLIC_URL}/assets/icons/day/clear.svg`;
const Cloudy1Icon = `${process.env.PUBLIC_URL}/assets/icons/day/cloudy_1.svg`;
const Cloudy2Icon = `${process.env.PUBLIC_URL}/assets/icons/day/cloudy_2.svg`;
const Cloudy3Icon = `${process.env.PUBLIC_URL}/assets/icons/day/cloudy_3.svg`;
const Cloudy4Icon = `${process.env.PUBLIC_URL}/assets/icons/day/cloudy_4.svg`;
const Cloudy5Icon = `${process.env.PUBLIC_URL}/assets/icons/day/cloudy_5.svg`;
const Cloudy6Icon = `${process.env.PUBLIC_URL}/assets/icons/day/cloudy_6.svg`;
const Cirrus1Icon = `${process.env.PUBLIC_URL}/assets/icons/day/cirrus_1.svg`;
const Cirrus2Icon = `${process.env.PUBLIC_URL}/assets/icons/day/cirrus_2.svg`;
const Cirrus3Icon = `${process.env.PUBLIC_URL}/assets/icons/day/cirrus_3.svg`;
const Cirrus4Icon = `${process.env.PUBLIC_URL}/assets/icons/day/cirrus_4.svg`;
const Rain1Icon = `${process.env.PUBLIC_URL}/assets/icons/day/rain_1.svg`;
const Rain2Icon = `${process.env.PUBLIC_URL}/assets/icons/day/rain_2.svg`;
const Rain3Icon = `${process.env.PUBLIC_URL}/assets/icons/day/rain_3.svg`;
const Rain4Icon = `${process.env.PUBLIC_URL}/assets/icons/day/rain_4.svg`;
const RainShower1Icon = `${process.env.PUBLIC_URL}/assets/icons/day/rain_shower_1.svg`;
const RainShower2Icon = `${process.env.PUBLIC_URL}/assets/icons/day/rain_shower_2.svg`;
const RainShower3Icon = `${process.env.PUBLIC_URL}/assets/icons/day/rain_shower_3.svg`;
const RainShower4Icon = `${process.env.PUBLIC_URL}/assets/icons/day/rain_shower_4.svg`;
const RainSnow1Icon = `${process.env.PUBLIC_URL}/assets/icons/day/rain_snow_1.svg`;
const RainSnow2Icon = `${process.env.PUBLIC_URL}/assets/icons/day/rain_snow_2.svg`;
const RainSnow3Icon = `${process.env.PUBLIC_URL}/assets/icons/day/rain_snow_3.svg`;
const RainSnowShower1Icon = `${process.env.PUBLIC_URL}/assets/icons/day/rain_snow_shower_1.svg`;
const RainSnowShower2Icon = `${process.env.PUBLIC_URL}/assets/icons/day/rain_snow_shower_2.svg`;
const RainSnowShower3Icon = `${process.env.PUBLIC_URL}/assets/icons/day/rain_snow_shower_3.svg`;
const RainSnowShower4Icon = `${process.env.PUBLIC_URL}/assets/icons/day/rain_snow_shower_4.svg`;
const IceRain1Icon = `${process.env.PUBLIC_URL}/assets/icons/day/ice_rain_1.svg`;
const IceRain2Icon = `${process.env.PUBLIC_URL}/assets/icons/day/ice_rain_2.svg`;
const IceRain3Icon = `${process.env.PUBLIC_URL}/assets/icons/day/ice_rain_3.svg`;
const IceRain4Icon = `${process.env.PUBLIC_URL}/assets/icons/day/ice_rain_4.svg`;
const Snow1Icon = `${process.env.PUBLIC_URL}/assets/icons/day/snow_1.svg`;
const Snow2Icon = `${process.env.PUBLIC_URL}/assets/icons/day/snow_2.svg`;
const Snow3Icon = `${process.env.PUBLIC_URL}/assets/icons/day/snow_3.svg`;
const Snow4Icon = `${process.env.PUBLIC_URL}/assets/icons/day/snow_4.svg`;
const SnowShower1Icon = `${process.env.PUBLIC_URL}/assets/icons/day/snow_shower_1.svg`;
const SnowShower2Icon = `${process.env.PUBLIC_URL}/assets/icons/day/snow_shower_2.svg`;
const SnowShower3Icon = `${process.env.PUBLIC_URL}/assets/icons/day/snow_shower_3.svg`;
const SnowShower4Icon = `${process.env.PUBLIC_URL}/assets/icons/day/snow_shower_4.svg`;
const Storm1Icon = `${process.env.PUBLIC_URL}/assets/icons/day/storm_1.svg`;
const Storm2Icon = `${process.env.PUBLIC_URL}/assets/icons/day/storm_2.svg`;
const Storm3Icon = `${process.env.PUBLIC_URL}/assets/icons/day/storm_3.svg`;
const StormShower1Icon = `${process.env.PUBLIC_URL}/assets/icons/day/storm_shower_1.svg`;
const StormShower2Icon = `${process.env.PUBLIC_URL}/assets/icons/day/storm_shower_2.svg`;
const StormShower3Icon = `${process.env.PUBLIC_URL}/assets/icons/day/storm_shower_3.svg`;
const StormShower4Icon = `${process.env.PUBLIC_URL}/assets/icons/day/storm_shower_4.svg`;

// Icônes nuit
const NightClearIcon = `${process.env.PUBLIC_URL}/assets/icons/night/clear.svg`;
const NightCloudy1Icon = `${process.env.PUBLIC_URL}/assets/icons/night/cloudy_1.svg`;
const NightCloudy2Icon = `${process.env.PUBLIC_URL}/assets/icons/night/cloudy_2.svg`;
const NightCloudy3Icon = `${process.env.PUBLIC_URL}/assets/icons/night/cloudy_3.svg`;
const NightCloudy4Icon = `${process.env.PUBLIC_URL}/assets/icons/night/cloudy_4.svg`;
const NightCloudy5Icon = `${process.env.PUBLIC_URL}/assets/icons/night/cloudy_5.svg`;
const NightCloudy6Icon = `${process.env.PUBLIC_URL}/assets/icons/night/cloudy_6.svg`;
const NightCirrus1Icon = `${process.env.PUBLIC_URL}/assets/icons/night/cirrus_1.svg`;
const NightCirrus2Icon = `${process.env.PUBLIC_URL}/assets/icons/night/cirrus_2.svg`;
const NightCirrus3Icon = `${process.env.PUBLIC_URL}/assets/icons/night/cirrus_3.svg`;
const NightCirrus4Icon = `${process.env.PUBLIC_URL}/assets/icons/night/cirrus_4.svg`;
const NightRain1Icon = `${process.env.PUBLIC_URL}/assets/icons/night/rain_1.svg`;
const NightRain2Icon = `${process.env.PUBLIC_URL}/assets/icons/night/rain_2.svg`;
const NightRain3Icon = `${process.env.PUBLIC_URL}/assets/icons/night/rain_3.svg`;
const NightRain4Icon = `${process.env.PUBLIC_URL}/assets/icons/night/rain_4.svg`;
const NightRainShower1Icon = `${process.env.PUBLIC_URL}/assets/icons/night/rain_shower_1.svg`;
const NightRainShower2Icon = `${process.env.PUBLIC_URL}/assets/icons/night/rain_shower_2.svg`;
const NightRainShower3Icon = `${process.env.PUBLIC_URL}/assets/icons/night/rain_shower_3.svg`;
const NightRainShower4Icon = `${process.env.PUBLIC_URL}/assets/icons/night/rain_shower_4.svg`;
const NightRainSnow1Icon = `${process.env.PUBLIC_URL}/assets/icons/night/rain_snow_1.svg`;
const NightRainSnow2Icon = `${process.env.PUBLIC_URL}/assets/icons/night/rain_snow_2.svg`;
const NightRainSnow3Icon = `${process.env.PUBLIC_URL}/assets/icons/night/rain_snow_3.svg`;
const NightRainSnowShower1Icon = `${process.env.PUBLIC_URL}/assets/icons/night/rain_snow_shower_1.svg`;
const NightRainSnowShower2Icon = `${process.env.PUBLIC_URL}/assets/icons/night/rain_snow_shower_2.svg`;
const NightRainSnowShower3Icon = `${process.env.PUBLIC_URL}/assets/icons/night/rain_snow_shower_3.svg`;
const NightRainSnowShower4Icon = `${process.env.PUBLIC_URL}/assets/icons/night/rain_snow_shower_4.svg`;
const NightIceRain1Icon = `${process.env.PUBLIC_URL}/assets/icons/night/ice_rain_1.svg`;
const NightIceRain2Icon = `${process.env.PUBLIC_URL}/assets/icons/night/ice_rain_2.svg`;
const NightIceRain3Icon = `${process.env.PUBLIC_URL}/assets/icons/night/ice_rain_3.svg`;
const NightIceRain4Icon = `${process.env.PUBLIC_URL}/assets/icons/night/ice_rain_4.svg`;
const NightSnow1Icon = `${process.env.PUBLIC_URL}/assets/icons/night/snow_1.svg`;
const NightSnow2Icon = `${process.env.PUBLIC_URL}/assets/icons/night/snow_2.svg`;
const NightSnow3Icon = `${process.env.PUBLIC_URL}/assets/icons/night/snow_3.svg`;
const NightSnow4Icon = `${process.env.PUBLIC_URL}/assets/icons/night/snow_4.svg`;
const NightSnowShower1Icon = `${process.env.PUBLIC_URL}/assets/icons/night/snow_shower_1.svg`;
const NightSnowShower2Icon = `${process.env.PUBLIC_URL}/assets/icons/night/snow_shower_2.svg`;
const NightSnowShower3Icon = `${process.env.PUBLIC_URL}/assets/icons/night/snow_shower_3.svg`;
const NightSnowShower4Icon = `${process.env.PUBLIC_URL}/assets/icons/night/snow_shower_4.svg`;
const NightStorm1Icon = `${process.env.PUBLIC_URL}/assets/icons/night/storm_1.svg`;
const NightStorm2Icon = `${process.env.PUBLIC_URL}/assets/icons/night/storm_2.svg`;
const NightStorm3Icon = `${process.env.PUBLIC_URL}/assets/icons/night/storm_3.svg`;
const NightStormShower1Icon = `${process.env.PUBLIC_URL}/assets/icons/night/storm_shower_1.svg`;
const NightStormShower2Icon = `${process.env.PUBLIC_URL}/assets/icons/night/storm_shower_2.svg`;
const NightStormShower3Icon = `${process.env.PUBLIC_URL}/assets/icons/night/storm_shower_3.svg`;
const NightStormShower4Icon = `${process.env.PUBLIC_URL}/assets/icons/night/storm_shower_4.svg`;

// Tableaux séparés pour les icônes jour et nuit
const dayIcons = [
  ClearIcon,
  Cloudy1Icon, Cloudy2Icon, Cloudy3Icon, Cloudy4Icon, Cloudy5Icon, Cloudy6Icon,
  Cirrus1Icon, Cirrus2Icon, Cirrus3Icon, Cirrus4Icon,
  Rain1Icon, Rain2Icon, Rain3Icon, Rain4Icon,
  RainShower1Icon, RainShower2Icon, RainShower3Icon, RainShower4Icon,
  RainSnow1Icon, RainSnow2Icon, RainSnow3Icon,
  RainSnowShower1Icon, RainSnowShower2Icon, RainSnowShower3Icon, RainSnowShower4Icon,
  IceRain1Icon, IceRain2Icon, IceRain3Icon, IceRain4Icon,
  Snow1Icon, Snow2Icon, Snow3Icon, Snow4Icon,
  SnowShower1Icon, SnowShower2Icon, SnowShower3Icon, SnowShower4Icon,
  Storm1Icon, Storm2Icon, Storm3Icon,
  StormShower1Icon, StormShower2Icon, StormShower3Icon, StormShower4Icon
];

const nightIcons = [
  NightClearIcon,
  NightCloudy1Icon, NightCloudy2Icon, NightCloudy3Icon, NightCloudy4Icon, NightCloudy5Icon, NightCloudy6Icon,
  NightCirrus1Icon, NightCirrus2Icon, NightCirrus3Icon, NightCirrus4Icon,
  NightRain1Icon, NightRain2Icon, NightRain3Icon, NightRain4Icon,
  NightRainShower1Icon, NightRainShower2Icon, NightRainShower3Icon, NightRainShower4Icon,
  NightRainSnow1Icon, NightRainSnow2Icon, NightRainSnow3Icon,
  NightRainSnowShower1Icon, NightRainSnowShower2Icon, NightRainSnowShower3Icon, NightRainSnowShower4Icon,
  NightIceRain1Icon, NightIceRain2Icon, NightIceRain3Icon, NightIceRain4Icon,
  NightSnow1Icon, NightSnow2Icon, NightSnow3Icon, NightSnow4Icon,
  NightSnowShower1Icon, NightSnowShower2Icon, NightSnowShower3Icon, NightSnowShower4Icon,
  NightStorm1Icon, NightStorm2Icon, NightStorm3Icon,
  NightStormShower1Icon, NightStormShower2Icon, NightStormShower3Icon, NightStormShower4Icon
];

// Définir un type pour les périodes valides
type ForecastPeriod = 'morningData' | 'afternoonData' | 'eveningData' | 'nightData';

type ReliabilityType = 'bonne' | 'moyenne' | 'mauvaise';

interface PeriodData {
  temperature?: number;
  windGust?: number;
  icon: string;
}

interface ForecastData {
  morningData?: PeriodData;
  afternoonData?: PeriodData;
  eveningData?: PeriodData;
  nightData?: PeriodData;
  bulletin?: string; // Champ pour le bulletin
  alertOrange?: boolean; // Propriété pour l'alerte orange
  alertRed?: boolean; // Propriété pour l'alerte rouge
  reliability?: ReliabilityType; // Propriété pour la fiabilité
  sunrise?: string; // Heure du lever du soleil
  sunset?: string; // Heure du coucher du soleil
  iso0min?: string; // Altitude iso0°C minimale (modifié pour accepter des chaînes sans "m")
  iso0max?: string; // Altitude iso0°C maximale (modifié pour accepter des chaînes sans "m")
}

// Fonction pour obtenir le mode (la valeur la plus fréquente)
const mode = (arr: any[]) => {
  const counts: { [key: string]: number } = {};
  let maxCount = 0;
  let modeValue = arr[0];

  arr.forEach((val) => {
    counts[val] = (counts[val] || 0) + 1;
    if (counts[val] > maxCount) {
      maxCount = counts[val];
      modeValue = val;
    }
  });

  return modeValue;
};

// Nouvelle fonction pour déterminer si le coucher du soleil est avant 20h00
const isSunsetBefore20 = (sunset: string): boolean => {
  const sunsetDate = new Date(sunset);
  const sunsetHours = sunsetDate.getHours();
  const sunsetMinutes = sunsetDate.getMinutes();

  return sunsetHours < 20 || (sunsetHours === 20 && sunsetMinutes === 0);
};

// Fonction pour obtenir les données d'une période
const getPeriodData = (
  data: any[],
  startHour: number,
  endHour: number,
  type: 'min' | 'max' | 'avg',
  isNight: boolean
): PeriodData => {
  const filtered = data.filter((hourly: any) => {
    const hour = new Date(hourly.forecastStart).getHours();
    return hour >= startHour && hour <= endHour;
  });

  // Fournir des valeurs par défaut si aucune donnée n'est disponible
  if (filtered.length === 0) {
    return {
      icon: getWeatherIcon(0, isNight),
    };
  }

  const temperatures = filtered.map((hourly: any) => hourly.temperature);
  const windGusts = filtered.map((hourly: any) => hourly.windGust);
  const weatherCodes = filtered.map((hourly: any) => hourly.weatherCode);

  // Choisir min, max, ou avg selon le type demandé
  let temperature: number | undefined;
  if (type === 'min') {
    temperature = Math.min(...temperatures);
  } else if (type === 'max') {
    temperature = Math.max(...temperatures);
  } else {
    temperature =
      temperatures.reduce((sum: number, temp: number) => sum + temp, 0) /
      temperatures.length;
  }

  const maxWindGust = Math.max(...windGusts);
  const weatherCode = mode(weatherCodes);

  // Déterminer l'icône basée sur le weatherCode
  const icon = getWeatherIcon(weatherCode, isNight);

  return {
    temperature: temperature !== undefined ? Math.round(temperature) : undefined,
    windGust: Math.round(maxWindGust / 5) * 5, // **MODIFICATION**: Conserver l'arrondissement à 5 km/h
    icon,
  };
};

// Fonction pour calculer l'altitude iso0°C
const computeIso0 = (temperatures: Record<string, number | undefined>): string | undefined => {
  // Mapping pression/niveau à altitude (en mètres)
  const pressureToAltitude: Record<string, number> = {
    '2m': 450,
    '925hPa': 800,
    '975hPa': 900, // Ajout de 975hPa
    '900hPa': 1000,
    '850hPa': 1500,
    '800hPa': 2000,
    '700hPa': 3000,
    '600hPa': 4000,
    '1000hPa': 50, // Ajout de 1000hPa (exemple d'altitude, ajustez selon les données réelles)
  };

  // Collecter les altitudes et températures
  const altitudeTemps = Object.keys(pressureToAltitude)
    .map((p) => ({
      altitude: pressureToAltitude[p],
      temp: temperatures[p],
    }))
    .filter((at) => at.temp !== undefined)
    .sort((a, b) => a.altitude - b.altitude);

  // Itérer du plus bas au plus haut altitude
  for (let i = 0; i < altitudeTemps.length - 1; i++) {
    const current = altitudeTemps[i];
    const next = altitudeTemps[i + 1];

    if (
      (current.temp! >= 0 && next.temp! <= 0) ||
      (current.temp! <= 0 && next.temp! >= 0)
    ) {
      const deltaTemp = next.temp! - current.temp!;
      if (deltaTemp === 0) return undefined; // Éviter la division par zéro

      const deltaAltitude = next.altitude - current.altitude;
      const altitudeIso0 = current.altitude + ((0 - current.temp!) * deltaAltitude) / deltaTemp;

      // Arrondir à 50m près
      const roundedAltitude = Math.round(altitudeIso0 / 50) * 50;

      if (roundedAltitude < 100) {
        return '<100'; // **MODIFICATION**: Retirer le "m"
      } else if (roundedAltitude > 4000) {
        return '>4000'; // **MODIFICATION**: Retirer le "m"
      } else {
        return `${roundedAltitude}`; // **MODIFICATION**: Retirer le "m"
      }
    }
  }

  // Si aucune traversée trouvée
  return undefined;
};

// Fonction pour calculer les températures minimales par niveau de pression et 2m
const computeMinTemperatures = (hoursForDay: any[]): Record<string, number | undefined> => {
  const pressureLevels = ['2m', '925hPa', '975hPa', '900hPa', '850hPa', '800hPa', '700hPa', '600hPa', '1000hPa'];
  const mins: Record<string, number | undefined> = {};

  pressureLevels.forEach((p) => {
    mins[p] = undefined;
  });

  hoursForDay.forEach((hour: any) => {
    pressureLevels.forEach((p) => {
      const temp = p === '2m' ? hour['temperature_2m'] : hour[`temperature_${p}`];
      if (temp !== undefined && temp !== null) {
        if (mins[p] === undefined || temp < mins[p]!) {
          mins[p] = temp;
        }
      }
    });
  });

  return mins;
};

// Fonction pour calculer les températures maximales par niveau de pression et 2m
const computeMaxTemperatures = (hoursForDay: any[]): Record<string, number | undefined> => {
  const pressureLevels = ['2m', '925hPa', '975hPa', '900hPa', '850hPa', '800hPa', '700hPa', '600hPa', '1000hPa'];
  const maxs: Record<string, number | undefined> = {};

  pressureLevels.forEach((p) => {
    maxs[p] = undefined;
  });

  hoursForDay.forEach((hour: any) => {
    pressureLevels.forEach((p) => {
      const temp = p === '2m' ? hour['temperature_2m'] : hour[`temperature_${p}`];
      if (temp !== undefined && temp !== null) {
        if (maxs[p] === undefined || temp > maxs[p]!) {
          maxs[p] = temp;
        }
      }
    });
  });

  return maxs;
};

// Fonction pour calculer l'altitude iso0°C minimale et maximale
const computeIso0Range = (hoursForDay: any[]): { iso0min?: string; iso0max?: string } => {
  const minTemperatures = computeMinTemperatures(hoursForDay);
  const maxTemperatures = computeMaxTemperatures(hoursForDay);

  const iso0min = computeIso0(minTemperatures); // Basé sur les températures minimales
  const iso0max = computeIso0(maxTemperatures); // Basé sur les températures maximales

  return { iso0min, iso0max };
};

// Fonction pour supprimer les champs avec des valeurs undefined
const removeUndefined = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map(removeUndefined);
  } else if (obj && typeof obj === 'object') {
    const newObj: any = {};
    Object.keys(obj).forEach((key) => {
      const value = removeUndefined(obj[key]);
      if (value !== undefined) {
        newObj[key] = value;
      }
    });
    return newObj;
  } else {
    return obj;
  }
};

// Composant Principal
const Previsions: React.FC = () => {
  const [weatherData, setWeatherData] = useState<any>(null);
  const [expertForecasts, setExpertForecasts] = useState<Record<string, ForecastData>>({});
  const [sidePanelOpen, setSidePanelOpen] = useState<boolean>(false);
  const [editingDay, setEditingDay] = useState<{
    day: string;
    period: ForecastPeriod;
  } | null>(null);

  // Nouveaux états pour le loader et les messages
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string | null>(null);
  const [messageType, setMessageType] = useState<'success' | 'error' | null>(null);

  // Nouveaux états pour le décalage manuel
  const [shiftLoading, setShiftLoading] = useState<boolean>(false);
  const [shiftMessage, setShiftMessage] = useState<string | null>(null);
  const [shiftMessageType, setShiftMessageType] = useState<'success' | 'error' | null>(null);

  const sidePanelRef = useRef<HTMLDivElement>(null);

  // Fonction pour fermer le sidepanel lorsque vous cliquez en dehors
  const handleClickOutside = (event: MouseEvent) => {
    if (sidePanelRef.current && !sidePanelRef.current.contains(event.target as Node)) {
      setSidePanelOpen(false);
    }
  };

  // Ajouter et retirer l'écouteur d'événements lors du montage et du démontage
  useEffect(() => {
    if (sidePanelOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [sidePanelOpen]);

  // Effet pour masquer le message après 5 secondes
  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => {
        setMessage(null);
        setMessageType(null);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [message]);

  // Effet pour masquer le message de décalage après 5 secondes
  useEffect(() => {
    if (shiftMessage) {
      const timer = setTimeout(() => {
        setShiftMessage(null);
        setShiftMessageType(null);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [shiftMessage]);

  // Effet pour récupérer les données météo
  useEffect(() => {
    const fetchWeatherData = async () => {
      try {
        const response = await axios.get(
          'https://api.open-meteo.com/v1/forecast?latitude=45.9088&longitude=6.1257&current_weather=true&hourly=temperature_2m,temperature_925hPa,temperature_975hPa,temperature_1000hPa,temperature_900hPa,temperature_850hPa,temperature_800hPa,temperature_700hPa,temperature_600hPa,apparent_temperature,weather_code,wind_gusts_10m,is_day&daily=sunrise,sunset&timezone=Europe%2FBerlin&forecast_days=14&models=best_match'
        );

        // Vérifier la présence des nouveaux champs
        const hasTemperature975hPa = response.data.hourly.temperature_975hPa !== undefined;
        const hasTemperature1000hPa = response.data.hourly.temperature_1000hPa !== undefined;

        if (!hasTemperature975hPa || !hasTemperature1000hPa) {
          console.warn('Les champs temperature_975hPa ou temperature_1000hPa ne sont pas présents dans la réponse de l\'API.');
        }

        const transformedData = {
          forecastDaily: {
            days: response.data.daily.sunrise.map((sunrise: string, index: number) => ({
              forecastStart: response.data.daily.sunrise[index],
              forecastEnd: response.data.daily.sunset[index],
              sunrise: response.data.daily.sunrise[index],
              sunset: response.data.daily.sunset[index],
            })),
          },
          forecastHourly: {
            hours: response.data.hourly.time.map((time: string, index: number) => ({
              forecastStart: time,
              temperature: response.data.hourly.temperature_2m[index], // Temp à 2m (450m)
              temperature_2m: response.data.hourly.temperature_2m[index],
              temperature_975hPa: hasTemperature975hPa ? response.data.hourly.temperature_975hPa[index] : undefined, // Nouveau champ avec vérification
              temperature_1000hPa: hasTemperature1000hPa ? response.data.hourly.temperature_1000hPa[index] : undefined, // Nouveau champ avec vérification
              temperature_925hPa: response.data.hourly.temperature_925hPa[index],
              temperature_900hPa: response.data.hourly.temperature_900hPa[index],
              temperature_850hPa: response.data.hourly.temperature_850hPa[index],
              temperature_800hPa: response.data.hourly.temperature_800hPa[index],
              temperature_700hPa: response.data.hourly.temperature_700hPa[index],
              temperature_600hPa: response.data.hourly.temperature_600hPa[index],
              apparent_temperature: response.data.hourly.apparent_temperature[index],
              windGust: response.data.hourly.wind_gusts_10m[index],
              weatherCode: response.data.hourly.weather_code[index],
              is_day: response.data.hourly.is_day[index],
            })),
          },
        };
        setWeatherData(transformedData);
      } catch (error) {
        console.error('Erreur lors de la récupération des données météo:', error);
        setMessage('Erreur lors de la récupération des données météo.');
        setMessageType('error');
      }
    };
    fetchWeatherData();
  }, []);

  // Effet pour récupérer les prévisions sauvegardées
  useEffect(() => {
    const fetchSavedForecasts = async () => {
      const docRef = doc(db, 'forecasts', 'expertForecasts');
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setExpertForecasts(docSnap.data() as Record<string, ForecastData>);
      } else {
        console.log('Aucune prévision sauvegardée trouvée!');
      }
    };

    fetchSavedForecasts();
  }, []);

  const openSidePanel = (dayNumber: string, period: ForecastPeriod) => {
    setEditingDay({ day: dayNumber, period });
    setSidePanelOpen(true);
  };

  const updateExpertForecast = (dayNumber: string, updatedForecast: ForecastData) => {
    setExpertForecasts((prevForecasts) => ({
      ...prevForecasts,
      [dayNumber]: {
        ...prevForecasts[dayNumber],
        ...updatedForecast,
        sunrise: updatedForecast.sunrise || prevForecasts[dayNumber]?.sunrise,
        sunset: updatedForecast.sunset || prevForecasts[dayNumber]?.sunset,
        iso0min: updatedForecast.iso0min ?? prevForecasts[dayNumber]?.iso0min,
        iso0max: updatedForecast.iso0max ?? prevForecasts[dayNumber]?.iso0max,
      },
    }));
  };

  const updateExpertForecastForPeriod = (
    dayNumber: string,
    period: ForecastPeriod,
    updatedData: PeriodData
  ) => {
    setExpertForecasts((prevForecasts) => {
      const dayKey = `${dayNumber}-expert`;
      const existingDayData = prevForecasts[dayKey] || {
        morningData: { icon: getWeatherIcon(0, false) },
        afternoonData: { icon: getWeatherIcon(0, false) },
        eveningData: { icon: getWeatherIcon(0, false) },
        nightData: { icon: getWeatherIcon(0, false) },
        bulletin: '',
        alertOrange: false,
        alertRed: false,
        sunrise: '', // Valeurs par défaut si nécessaires
        sunset: '',
        iso0min: undefined,
        iso0max: undefined,
      };

      return {
        ...prevForecasts,
        [dayKey]: {
          ...existingDayData,
          [period]: updatedData,
        },
      };
    });
  };

  const resetExpertForecastForDay = (dayNumber: string) => {
    const dayIndex = parseInt(dayNumber.substring(1), 10);

    if (!weatherData || !weatherData.forecastDaily || !weatherData.forecastDaily.days[dayIndex]) {
      console.error(`Aucune donnée disponible pour le jour ${dayNumber}`);
      setMessage(`Aucune donnée disponible pour le jour ${dayNumber}.`);
      setMessageType('error');
      return;
    }

    const apiDay = weatherData.forecastDaily.days[dayIndex];

    const hoursForDay = weatherData.forecastHourly.hours.filter((hour: any) => {
      const forecastTime = new Date(hour.forecastStart).getTime();
      const dayStart = new Date(apiDay.forecastStart).setHours(0, 0, 0, 0);
      const dayEnd = new Date(apiDay.forecastEnd).setHours(23, 59, 59, 999);
      return forecastTime >= dayStart && forecastTime <= dayEnd;
    });

    if (!hoursForDay || hoursForDay.length === 0) {
      console.error(`Aucune donnée horaire disponible pour le jour ${dayNumber}`);
      setMessage(`Aucune donnée horaire disponible pour le jour ${dayNumber}.`);
      setMessageType('error');
      return;
    }

    // Calculer la fourchette iso0min et iso0max
    const { iso0min, iso0max } = computeIso0Range(hoursForDay);

    // Déterminer si le coucher du soleil est avant 20h00
    const isEveningNight = isSunsetBefore20(apiDay.sunset);

    // Mettre à jour uniquement les prévisions (pictos, températures, vents) et iso0min/max
    const newExpertForecast: Partial<ForecastData> = {
      morningData: getPeriodData(hoursForDay, 6, 11, 'min', false),
      afternoonData: getPeriodData(hoursForDay, 12, 17, 'max', false),
      eveningData: getPeriodData(hoursForDay, 18, 23, 'avg', isEveningNight), // Utilisation de isEveningNight
      // Pour la nuit, récupérer les données du lendemain
      nightData: getPeriodData(
        weatherData.forecastHourly.hours.filter((hour: any) => {
          const forecastTime = new Date(hour.forecastStart).getTime();
          const nextDay = new Date(apiDay.forecastStart);
          nextDay.setDate(nextDay.getDate() + 1);
          const dayStart = new Date(nextDay).setHours(0, 0, 0, 0);
          const dayEnd = new Date(nextDay).setHours(5, 59, 59, 999);
          return forecastTime >= dayStart && forecastTime <= dayEnd;
        }),
        0,
        5,
        'avg',
        true
      ),
      sunrise: apiDay.sunrise,
      sunset: apiDay.sunset,
      iso0min: iso0min,
      iso0max: iso0max,
    };

    setExpertForecasts((prevForecasts) => ({
      ...prevForecasts,
      [`${dayNumber}-expert`]: {
        ...prevForecasts[`${dayNumber}-expert`],
        morningData: newExpertForecast.morningData,
        afternoonData: newExpertForecast.afternoonData,
        eveningData: newExpertForecast.eveningData,
        nightData: newExpertForecast.nightData,
        sunrise: newExpertForecast.sunrise,
        sunset: newExpertForecast.sunset,
        iso0min: newExpertForecast.iso0min,
        iso0max: newExpertForecast.iso0max,
      },
    }));
  };

  const saveForecasts = async () => {
    setLoading(true);
    setMessage(null);
    setMessageType(null);
    try {
      const allForecasts: Record<string, ForecastData> = {};

      const fullDays = [...weatherData.forecastDaily.days];

      // S'assurer que fullDays a des données valides
      while (fullDays.length < 10) {
        const lastDay = fullDays[fullDays.length - 1];
        const nextDayDate = new Date(lastDay.forecastStart);
        nextDayDate.setDate(nextDayDate.getDate() + 1);
        const nextDay = {
          forecastStart: nextDayDate.toISOString(),
          forecastEnd: nextDayDate.toISOString(),
          sunrise: nextDayDate.toISOString(),
          sunset: nextDayDate.toISOString(),
        };
        fullDays.push(nextDay);
      }

      for (let index = 0; index < fullDays.length; index++) {
        const day = fullDays[index];

        if (!day || !day.forecastStart) {
          console.warn(`Day at index ${index} is invalid or missing forecastStart`);
          continue; // Skip this day
        }

        const startDate = new Date(fullDays[0].forecastStart);
        const daysDifference = Math.floor(
          (new Date(day.forecastStart).getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)
        );
        const dayNumber = `J${daysDifference}`;
        const forecastKey = `${dayNumber}-expert`;

        // Compute iso0min and iso0max from API data
        const dayStart = new Date(day.forecastStart).setHours(0, 0, 0, 0);
        const dayEnd = new Date(day.forecastStart).setHours(23, 59, 59, 999);

        const hoursForDay = weatherData.forecastHourly.hours.filter((hour: any) => {
          const forecastTime = new Date(hour.forecastStart).getTime();
          return forecastTime >= dayStart && forecastTime <= dayEnd;
        });

        const { iso0min, iso0max } = computeIso0Range(hoursForDay);

        // Déterminer si le coucher du soleil est avant 20h00
        const isEveningNight = isSunsetBefore20(day.sunset);

        let forecastData: ForecastData;

        if (expertForecasts[forecastKey]) {
          forecastData = {
            ...expertForecasts[forecastKey],
            iso0min: expertForecasts[forecastKey].iso0min || iso0min,
            iso0max: expertForecasts[forecastKey].iso0max || iso0max,
            sunrise: expertForecasts[forecastKey].sunrise || day.sunrise,
            sunset: expertForecasts[forecastKey].sunset || day.sunset,
          };
        } else {
          const morningData = getPeriodData(hoursForDay, 6, 11, 'min', false);
          const afternoonData = getPeriodData(hoursForDay, 12, 17, 'max', false);
          const eveningData = getPeriodData(hoursForDay, 18, 23, 'avg', isEveningNight); // Utilisation de isEveningNight

          // Obtenir les données de nuit du lendemain
          const nextDay = fullDays[index + 1];
          const nextDayNightHours = nextDay
            ? weatherData.forecastHourly.hours.filter((hour: any) => {
              const forecastTime = new Date(hour.forecastStart).getTime();
              const nextDayDate = new Date(nextDay.forecastStart);
              return (
                forecastTime >= new Date(nextDayDate).setHours(0, 0, 0, 0) &&
                forecastTime < new Date(nextDayDate).setHours(6, 0, 0, 0)
              );
            })
            : [];

          const nightData =
            nextDayNightHours.length > 0
              ? getPeriodData(nextDayNightHours, 0, 5, 'avg', true)
              : {
                temperature: undefined,
                windGust: undefined,
                icon: getWeatherIcon(0, true),
              };

          forecastData = {
            morningData,
            afternoonData,
            eveningData,
            nightData,
            sunrise: day.sunrise,
            sunset: day.sunset,
            iso0min: iso0min,
            iso0max: iso0max,
          };
        }

        // Supprimer les champs avec des valeurs undefined
        forecastData = removeUndefined(forecastData);

        allForecasts[forecastKey] = forecastData;
      }

      // Supprimer les champs undefined de allForecasts
      const cleanedForecasts = removeUndefined(allForecasts);

      await setDoc(doc(db, 'forecasts', 'expertForecasts'), cleanedForecasts);
      setMessage('Prévisions mises à jour avec succès !');
      setMessageType('success');
    } catch (error) {
      console.error('Erreur lors de la sauvegarde des prévisions:', error);
      setMessage('Erreur lors de la mise à jour des prévisions.');
      setMessageType('error');
    } finally {
      setLoading(false);
    }
  };

  const handleManualShift = async () => {
    setShiftLoading(true);
    setShiftMessage(null);
    setShiftMessageType(null);

    try {
      // Appeler la fonction Cloud via HTTP
      await axios.get(
        'https://us-central1-annecymeteo-49ea3.cloudfunctions.net/shiftForecastsManual'
      );
      setShiftMessage('Décalage des prévisions effectué avec succès.');
      setShiftMessageType('success');

      // Mettre à jour les prévisions depuis Firestore
      const docRef = doc(db, 'forecasts', 'expertForecasts');
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setExpertForecasts(docSnap.data() as Record<string, ForecastData>);
      } else {
        console.log('Aucune prévision sauvegardée trouvée!');
        setShiftMessage('Aucune prévision sauvegardée trouvée.');
        setShiftMessageType('error');
      }
    } catch (error) {
      console.error('Erreur lors du décalage manuel des prévisions:', error);
      setShiftMessage('Erreur lors du décalage des prévisions.');
      setShiftMessageType('error');
    } finally {
      setShiftLoading(false);
    }
  };

  if (!weatherData) {
    return <div>Chargement des données météo...</div>;
  }

  const { forecastDaily, forecastHourly } = weatherData;

  const currentDate = new Date().setHours(0, 0, 0, 0);

  const filteredDays = forecastDaily.days.filter((day: any) => {
    const dayDate = new Date(day.forecastStart).setHours(0, 0, 0, 0);
    return dayDate >= currentDate;
  });

  const startDate = new Date(filteredDays[0].forecastStart);
  const fullDays = [...filteredDays];

  // S'assurer que fullDays a des données valides
  while (fullDays.length < 10) {
    const lastDay = fullDays[fullDays.length - 1];
    const nextDayDate = new Date(lastDay.forecastStart);
    nextDayDate.setDate(nextDayDate.getDate() + 1);
    const nextDay = {
      forecastStart: nextDayDate.toISOString(),
      forecastEnd: nextDayDate.toISOString(),
      sunrise: nextDayDate.toISOString(),
      sunset: nextDayDate.toISOString(),
    };
    fullDays.push(nextDay);
  }

  const renderDayPart = (
    label: string,
    data: PeriodData | undefined,
    isNight: boolean,
    isExpertised: boolean,
    onIconClick?: () => void,
    onDataChange?: (updatedData: PeriodData) => void
  ) => {
    if (!data) {
      return (
        <div className="day-part" key={label}>
          <h4>{label}</h4>
          <p>Données non disponibles</p>
        </div>
      );
    }

    const icon = data.icon;

    // Arrondir les valeurs pour l'affichage
    const roundedTemperature =
      data.temperature !== undefined ? `${Math.round(data.temperature)}°` : 'N/A';
    const roundedWindGust =
      data.windGust !== undefined ? `${Math.round(data.windGust)}` : 'N/A'; // **MODIFICATION**: Affichage sans arrondir à 5

    return (
      <div className="day-part" key={label}>
        <h4>{label}</h4>

        {/* Le sidepanel s'ouvre uniquement lorsque l'utilisateur clique sur l'icône */}
        <img
          src={icon}
          alt="weather-icon"
          className="weather-icon"
          onClick={onIconClick}
        />

        {isExpertised && (
          <>
            <div className="input-label-group">
              <label htmlFor={`temperature-${label}`}>Temp</label>
              <input
                id={`temperature-${label}`}
                type="number"
                value={data.temperature !== undefined ? data.temperature : ''}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (inputValue === '') {
                    onDataChange && onDataChange({ ...data, temperature: undefined });
                  } else {
                    const parsedValue = parseFloat(inputValue);
                    if (!isNaN(parsedValue)) {
                      const newTemp = Math.round(parsedValue);
                      onDataChange && onDataChange({ ...data, temperature: newTemp });
                    }
                  }
                }}
                className="input-standard"
              />
            </div>
            <div className="input-label-group">
              <label htmlFor={`windGust-${label}`}>Rafales</label>
              <input
                id={`windGust-${label}`}
                type="number"
                value={data.windGust !== undefined ? data.windGust : ''}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (inputValue === '') {
                    onDataChange && onDataChange({ ...data, windGust: undefined });
                  } else {
                    const parsedValue = parseFloat(e.target.value);
                    if (!isNaN(parsedValue)) {
                      onDataChange && onDataChange({ ...data, windGust: parsedValue }); // **MODIFICATION**: Suppression de l'arrondissement
                    }
                  }
                }}
                className="input-standard"
              />
            </div>
          </>
        )}
        {!isExpertised && (
          <>
            <p>{roundedTemperature}</p>
            <p>
              Rafales: <br /> {roundedWindGust !== 'N/A' ? `${roundedWindGust} km/h` : 'N/A'}
            </p>
          </>
        )}
      </div>
    );
  };

  const renderDay = (
    day: any,
    index: number,
    hourlyData: any[],
    startDate: Date,
    isExpertised: boolean,
    expertForecasts: Record<string, ForecastData>,
    updateExpertForecast: (dayNumber: string, updatedForecast: ForecastData) => void,
    resetExpertForecastForDay: (dayNumber: string) => void,
    openSidePanel: (dayNumber: string, period: ForecastPeriod) => void,
    nextDayNightHours: any[] // Nouveau paramètre
  ) => {
    const dayStart = new Date(day.forecastStart).setHours(0, 0, 0, 0);
    const dayEnd = new Date(day.forecastStart).setHours(23, 59, 59, 999);

    const hoursForDay = hourlyData.filter((hour: any) => {
      const forecastTime = new Date(hour.forecastStart).getTime();
      return forecastTime >= dayStart && forecastTime <= dayEnd;
    });

    if (!hoursForDay || hoursForDay.length === 0) {
      return (
        <div
          key={`${index}-${isExpertised ? 'expert' : 'non-expert'}`}
          className="day-block"
        >
          <h3>
            Jour {index + 1} - {new Date(day.forecastStart).toLocaleDateString()}
          </h3>
          <p>Données horaires non disponibles pour ce jour.</p>
        </div>
      );
    }

    // Données de la journée
    const morningData = getPeriodData(hoursForDay, 6, 11, 'min', false);
    const afternoonData = getPeriodData(hoursForDay, 12, 17, 'max', false);

    // Déterminer si le coucher du soleil est avant 20h00
    const isEveningNight = isSunsetBefore20(day.sunset);
    const eveningData = getPeriodData(hoursForDay, 18, 23, 'avg', isEveningNight); // Utilisation de isEveningNight

    // Utiliser les données de nuit du lendemain
    const nightData =
      nextDayNightHours.length > 0
        ? getPeriodData(nextDayNightHours, 0, 5, 'avg', true)
        : {
          temperature: undefined,
          windGust: undefined,
          icon: getWeatherIcon(0, true),
        };

    // Calculer la fourchette iso0min et iso0max
    const { iso0min, iso0max } = computeIso0Range(hoursForDay);

    const daysDifference = Math.floor(
      (new Date(day.forecastStart).getTime() - startDate.getTime()) /
      (1000 * 60 * 60 * 24)
    );
    const dayNumber = `J${daysDifference}`;

    const forecastKey = `${dayNumber}-expert`;
    const expertData = expertForecasts[forecastKey] || {};

    const forecastData: ForecastData = {
      morningData: isExpertised
        ? { ...morningData, ...expertData.morningData }
        : morningData,
      afternoonData: isExpertised
        ? { ...afternoonData, ...expertData.afternoonData }
        : afternoonData,
      eveningData: isExpertised
        ? { ...eveningData, ...expertData.eveningData }
        : eveningData,
      nightData: isExpertised
        ? { ...nightData, ...expertData.nightData }
        : nightData,
      bulletin: isExpertised ? expertData.bulletin || '' : '',
      alertOrange: isExpertised ? expertData.alertOrange || false : false,
      alertRed: isExpertised ? expertData.alertRed || false : false,
      reliability: isExpertised ? expertData.reliability || 'bonne' : undefined,
      sunrise: expertData.sunrise || day.sunrise, // Assurez-vous que sunrise est inclus
      sunset: expertData.sunset || day.sunset, // Assurez-vous que sunset est inclus
      iso0min: isExpertised ? expertData.iso0min || iso0min : iso0min,
      iso0max: isExpertised ? expertData.iso0max || iso0max : iso0max,
    };

    // Définir les valeurs de iso0min et iso0max pour les inputs en tant que string ou undefined
    const iso0minValue: string | undefined =
      forecastData.iso0min !== undefined ? forecastData.iso0min : '';
    const iso0maxValue: string | undefined =
      forecastData.iso0max !== undefined ? forecastData.iso0max : '';

    return (
      <div
        key={`${index}-${isExpertised ? 'expert' : 'non-expert'}`}
        className={`day-block ${isExpertised ? 'expert-block' : ''} ${forecastData.alertOrange ? 'alert-orange' : ''
          } ${forecastData.alertRed ? 'alert-red' : ''}`}
      >
        <h3>
          {dayNumber} -{' '}
          {new Date(day.forecastStart).toLocaleDateString(undefined, {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })}
        </h3>

        {isExpertised && (
          <div className="alert-switches">
            {/* Switch pour l'Alerte Orange */}
            <label className="switch switch-orange">
              <input
                type="checkbox"
                checked={forecastData.alertOrange || false}
                onChange={() => {
                  setExpertForecasts((prevForecasts) => {
                    const dayKey = `${dayNumber}-expert`;
                    const existingDayData = prevForecasts[dayKey] || {
                      morningData: { icon: getWeatherIcon(0, false) },
                      afternoonData: { icon: getWeatherIcon(0, false) },
                      eveningData: { icon: getWeatherIcon(0, false) },
                      nightData: { icon: getWeatherIcon(0, false) },
                      bulletin: '',
                      alertOrange: false,
                      alertRed: false,
                      sunrise: day.sunrise, // Initialiser sunrise
                      sunset: day.sunset, // Initialiser sunset
                      iso0min: forecastData.iso0min, // Initialiser iso0min
                      iso0max: forecastData.iso0max, // Initialiser iso0max
                    };
                    return {
                      ...prevForecasts,
                      [dayKey]: {
                        ...existingDayData,
                        alertOrange: !forecastData.alertOrange,
                      },
                    };
                  });
                }}
              />
              <span className="slider round"></span>
            </label>

            {/* Switch pour l'Alerte Rouge */}
            <label className="switch switch-red">
              <input
                type="checkbox"
                checked={forecastData.alertRed || false}
                onChange={() => {
                  setExpertForecasts((prevForecasts) => {
                    const dayKey = `${dayNumber}-expert`;
                    const existingDayData = prevForecasts[dayKey] || {
                      morningData: { icon: getWeatherIcon(0, false) },
                      afternoonData: { icon: getWeatherIcon(0, false) },
                      eveningData: { icon: getWeatherIcon(0, false) },
                      nightData: { icon: getWeatherIcon(0, false) },
                      bulletin: '',
                      alertOrange: false,
                      alertRed: false,
                      sunrise: day.sunrise, // Initialiser sunrise
                      sunset: day.sunset, // Initialiser sunset
                      iso0min: forecastData.iso0min, // Initialiser iso0min
                      iso0max: forecastData.iso0max, // Initialiser iso0max
                    };
                    return {
                      ...prevForecasts,
                      [dayKey]: {
                        ...existingDayData,
                        alertRed: !forecastData.alertRed,
                      },
                    };
                  });
                }}
              />
              <span className="slider round"></span>
            </label>

            {/* Sélecteur de fiabilité */}
            <div className="reliability-selector">
              <label>
                Fiabilité :
                <select
                  value={forecastData.reliability || 'bonne'}
                  onChange={(e) => {
                    const selectedReliability = e.target.value as ReliabilityType;
                    setExpertForecasts((prevForecasts) => {
                      const dayKey = `${dayNumber}-expert`;
                      const existingDayData = prevForecasts[dayKey] || {
                        morningData: { icon: getWeatherIcon(0, false) },
                        afternoonData: { icon: getWeatherIcon(0, false) },
                        eveningData: { icon: getWeatherIcon(0, false) },
                        nightData: { icon: getWeatherIcon(0, false) },
                        bulletin: '',
                        alertOrange: false,
                        alertRed: false,
                        sunrise: day.sunrise, // Initialiser sunrise
                        sunset: day.sunset, // Initialiser sunset
                        iso0min: forecastData.iso0min, // Initialiser iso0min
                        iso0max: forecastData.iso0max, // Initialiser iso0max
                      };
                      return {
                        ...prevForecasts,
                        [dayKey]: {
                          ...existingDayData,
                          reliability: selectedReliability,
                        },
                      };
                    });
                  }}
                >
                  <option value="bonne">Bonne</option>
                  <option value="moyenne">Moyenne</option>
                  <option value="mauvaise">Mauvaise</option>
                </select>
              </label>
            </div>
          </div>
        )}

        <div className="prevision">
          {renderDayPart(
            'Matin',
            forecastData.morningData,
            false,
            isExpertised,
            isExpertised ? () => openSidePanel(dayNumber, 'morningData') : undefined,
            isExpertised
              ? (updatedData: PeriodData) =>
                updateExpertForecastForPeriod(dayNumber, 'morningData', updatedData)
              : undefined
          )}

          {renderDayPart(
            'Aprèm',
            forecastData.afternoonData,
            false,
            isExpertised,
            isExpertised ? () => openSidePanel(dayNumber, 'afternoonData') : undefined,
            isExpertised
              ? (updatedData: PeriodData) =>
                updateExpertForecastForPeriod(dayNumber, 'afternoonData', updatedData)
              : undefined
          )}

          {renderDayPart(
            'Soirée',
            forecastData.eveningData,
            false,
            isExpertised,
            isExpertised ? () => openSidePanel(dayNumber, 'eveningData') : undefined,
            isExpertised
              ? (updatedData: PeriodData) =>
                updateExpertForecastForPeriod(dayNumber, 'eveningData', updatedData)
              : undefined
          )}

          {renderDayPart(
            'Nuit',
            forecastData.nightData,
            true,
            isExpertised,
            isExpertised ? () => openSidePanel(dayNumber, 'nightData') : undefined,
            isExpertised
              ? (updatedData: PeriodData) =>
                updateExpertForecastForPeriod(dayNumber, 'nightData', updatedData)
              : undefined
          )}

          {/* Intégration des champs Iso0°C */}
          <div className="iso0-field">
            <h4>Iso 0°C</h4>
            {isExpertised ? (
              <>
                <div className="input-label-group">
                  <label htmlFor={`iso0min-${dayNumber}`}>Min (m)</label>
                  <input
                    id={`iso0min-${dayNumber}`}
                    type="text" // Changement du type à "text"
                    value={iso0minValue}
                    onChange={(e) => {
                      const inputValue = e.target.value.trim();
                      updateExpertForecastForIso0(
                        dayNumber,
                        inputValue === '' ? undefined : inputValue,
                        'min'
                      );
                    }}
                    className="input-standard"
                    placeholder="Min Altitude"
                  />
                </div>
                <div className="input-label-group">
                  <label htmlFor={`iso0max-${dayNumber}`}>Max (m)</label>
                  <input
                    id={`iso0max-${dayNumber}`}
                    type="text" // Changement du type à "text"
                    value={iso0maxValue}
                    onChange={(e) => {
                      const inputValue = e.target.value.trim();
                      updateExpertForecastForIso0(
                        dayNumber,
                        inputValue === '' ? undefined : inputValue,
                        'max'
                      );
                    }}
                    className="input-standard"
                    placeholder="Max Altitude"
                  />
                </div>
              </>
            ) : (
              <p>
                {forecastData.iso0min !== undefined && forecastData.iso0max !== undefined
                  ? `${forecastData.iso0min}m - ${forecastData.iso0max}m` // **MODIFICATION**: Ajouter "m" uniquement dans l'affichage
                  : 'N/A'}
              </p>
            )}
          </div>
        </div>
        {/* Affichage et édition des champs bulletin */}
        {isExpertised && (
          <>
          <button onClick={() => resetExpertForecastForDay(dayNumber)}>
        Réinitialiser avec API
      </button>
            <textarea
              value={forecastData.bulletin || ''}
              onChange={(e) => {
                const newBulletin = e.target.value;
                setExpertForecasts((prevForecasts) => {
                  const dayKey = `${dayNumber}-expert`;
                  const existingDayData = prevForecasts[dayKey] || {
                    morningData: { icon: getWeatherIcon(0, false) },
                    afternoonData: { icon: getWeatherIcon(0, false) },
                    eveningData: { icon: getWeatherIcon(0, false) },
                    nightData: { icon: getWeatherIcon(0, false) },
                    bulletin: '',
                    alertOrange: false,
                    alertRed: false,
                    sunrise: day.sunrise, // Initialiser sunrise
                    sunset: day.sunset, // Initialiser sunset
                    iso0min: forecastData.iso0min, // Initialiser iso0min
                    iso0max: forecastData.iso0max, // Initialiser iso0max
                  };
                  return {
                    ...prevForecasts,
                    [dayKey]: {
                      ...existingDayData,
                      bulletin: newBulletin,
                    },
                  };
                });
              }}
              placeholder="Écrire le bulletin pour ce jour"
              style={{ overflow: 'hidden', resize: 'none' }}
              className="bulletin-textarea"
            />
          </>
        )}
      </div>
    );
  };

  // Fonction pour mettre à jour iso0min ou iso0max
  const updateExpertForecastForIso0 = (
    dayNumber: string,
    updatedIso0: string | undefined,
    type: 'min' | 'max'
  ) => {
    setExpertForecasts((prevForecasts) => {
      const dayKey = `${dayNumber}-expert`;
      const existingDayData = prevForecasts[dayKey] || {
        morningData: { icon: getWeatherIcon(0, false) },
        afternoonData: { icon: getWeatherIcon(0, false) },
        eveningData: { icon: getWeatherIcon(0, false) },
        nightData: { icon: getWeatherIcon(0, false) },
        bulletin: '',
        alertOrange: false,
        alertRed: false,
        sunrise: '', // Valeurs par défaut si nécessaires
        sunset: '',
        iso0min: undefined,
        iso0max: undefined,
      };

      return {
        ...prevForecasts,
        [dayKey]: {
          ...existingDayData,
          [type === 'min' ? 'iso0min' : 'iso0max']: updatedIso0, // **MODIFICATION**: Stocker sans "m"
        },
      };
    });
  };

  return (
    <div className="prevision-page">
      {/* Ajout du header */}
      <Header />

      <div className="prevision-container">
        {fullDays.map((day: any, index: number) => {
          // Obtenir le lendemain
          const nextDay = fullDays[index + 1];

          // Filtrer les heures du lendemain entre 00h et 05h59
          const nextDayNightHours = nextDay
            ? forecastHourly.hours.filter((hour: any) => {
              const forecastTime = new Date(hour.forecastStart);
              const nextDayDate = new Date(nextDay.forecastStart);
              return (
                forecastTime.getFullYear() === nextDayDate.getFullYear() &&
                forecastTime.getMonth() === nextDayDate.getMonth() &&
                forecastTime.getDate() === nextDayDate.getDate() &&
                forecastTime.getHours() >= 0 &&
                forecastTime.getHours() < 6
              );
            })
            : []; // Si pas de lendemain, tableau vide ou données par défaut

          return (
            <div key={index} className="combined-day-block">
              {renderDay(
                day,
                index,
                forecastHourly.hours,
                startDate,
                false,
                expertForecasts,
                updateExpertForecast,
                resetExpertForecastForDay,
                openSidePanel,
                nextDayNightHours // Passer les heures de nuit du lendemain
              )}
              {renderDay(
                day,
                index,
                forecastHourly.hours,
                startDate,
                true,
                expertForecasts,
                updateExpertForecast,
                resetExpertForecastForDay,
                openSidePanel,
                nextDayNightHours // Passer les heures de nuit du lendemain
              )}
            </div>
          );
        })}

        {sidePanelOpen && editingDay && (
          <div ref={sidePanelRef} className="side-panel">
            <h3>Choisir une icône</h3>
            <div className="icon-grid">
              {(() => {
                const { day, period } = editingDay;
                const dayKey = `${day}-expert`;
                const currentForecast = expertForecasts[dayKey] || {};
                let availableIcons: string[] = [];

                if (period === 'morningData' || period === 'afternoonData') {
                  availableIcons = dayIcons;
                } else if (period === 'eveningData') {
                  // Déterminer si le coucher du soleil est avant 20h00
                  const sunset = currentForecast.sunset || weatherData.forecastDaily.days[parseInt(day.substring(1), 10)].sunset;
                  const isEveningNight = isSunsetBefore20(sunset);
                  availableIcons = isEveningNight ? nightIcons : dayIcons;
                } else if (period === 'nightData') {
                  availableIcons = nightIcons;
                }

                return availableIcons.map((iconPath, index) => (
                  <img
                    key={index}
                    src={iconPath}
                    alt={`Icon ${index}`}
                    className="icon-choice"
                    onClick={() => {
                      if (editingDay) {
                        setExpertForecasts((prev) => {
                          const { day, period } = editingDay;
                          const dayKey = `${day}-expert`;

                          const existingDayData = prev[dayKey] || {
                            morningData: { icon: getWeatherIcon(0, false) },
                            afternoonData: { icon: getWeatherIcon(0, false) },
                            eveningData: { icon: getWeatherIcon(0, false) },
                            nightData: { icon: getWeatherIcon(0, false) },
                            bulletin: '',
                            alertOrange: false,
                            alertRed: false,
                            sunrise: '', // Valeurs par défaut si nécessaires
                            sunset: '',
                            iso0min: undefined,
                            iso0max: undefined,
                          };

                          const existingPeriodData: PeriodData =
                            existingDayData[period] || {
                              icon: getWeatherIcon(0, false),
                            };

                          const updatedPeriodData: PeriodData = {
                            ...existingPeriodData,
                            icon: iconPath,
                          };

                          return {
                            ...prev,
                            [dayKey]: {
                              ...existingDayData,
                              [period]: updatedPeriodData,
                            },
                          };
                        });
                      }
                      setSidePanelOpen(false);
                    }}
                  />
                ));
              })()}
            </div>
          </div>
        )}

        {/* Bouton de décalage manuel */}
        <div className="manual-shift-button">
          <button onClick={handleManualShift} disabled={shiftLoading}>
            {shiftLoading ? (
              <div className="loader"></div> /* Loader */
            ) : (
              'Forcer le décalage des prévisions'
            )}
          </button>
        </div>

        {/* Bouton avec loader */}
        <div className="bandeau-floating-button">
          <button className="floating-button" onClick={saveForecasts} disabled={loading}>
            {loading ? (
              <div className="loader"></div> /* Loader */
            ) : (
              'Publier les prévisions'
            )}
          </button>
        </div>

        {/* Bandeau de message pour le décalage manuel */}
        {shiftMessage && (
          <div className={`message-banner ${shiftMessageType}`}>{shiftMessage}</div>
        )}

        {/* Bandeau de message */}
        {message && <div className={`message-banner ${messageType}`}>{message}</div>}
      </div>
    </div>
  );
};

export default Previsions;