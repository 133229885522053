import React from 'react';
import { Link } from 'react-router-dom';
import { signOut } from 'firebase/auth'; // Import de la fonction signOut
import { auth } from './firebase'; // Import de la configuration Firebase
import './adminhome.css'; // Fichier CSS modernisé

const AdminHome = () => {
  // Fonction de déconnexion
  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        console.log("Utilisateur déconnecté");
        window.location.href = '/'; // Rediriger vers la page de connexion après déconnexion
      })
      .catch((error) => {
        console.error("Erreur lors de la déconnexion :", error);
      });
  };

  return (
    <div className="admin-home-container">
      <header className="admin-header">
        <h1 className="admin-title">Bienvenue sur le Back-office AnnecyMeteo</h1>
      </header>

      <nav className="admin-nav">
        <ul className="admin-nav-list">
          <li className="admin-nav-item">
            <Link to="/previsions" className="admin-nav-link">Gestion des Prévisions</Link>
          </li>
          <li className="admin-nav-item">
            <Link to="/bandeau" className="admin-nav-link">Gestion du Bandeau</Link>
          </li>
          <li className="admin-nav-item">
            <Link to="/notifications" className="admin-nav-link">Gestion des Notifications</Link>
          </li>
        </ul>
      </nav>

      <button className="admin-logout-button" onClick={handleLogout}>
        Déconnexion
      </button>
    </div>
  );
};

export default AdminHome;